import React, { CSSProperties, useEffect, useState } from "react";
import {
    Avatar,
    Badge,
    Card,
    Col,
    Collapse,
    Empty,
    Layout,
    List,
    Row,
    Typography,
} from "antd";
import { NextPage } from "next";
import PieChart from "../components/pieChart";
import BarChart from "../components/barChart";
import { PDFGenerator } from "../components/pdfGenerator";
import { OpenAPI, DefaultService } from "../client";
import { useAuth0 } from "@auth0/auth0-react";

const { Panel } = Collapse;
const { Text, Title } = Typography;
const { Content } = Layout;

type Software = {
    id: string;
    eol: string;
    purl: string;
    scanId: string;
    eolDate: string;
    imageName: string;
    productName: string;
    releaseDate: string;
    artifactName: string;
    artifactType: string;
    releaseCycle: string;
    imageLocation: string;
    artifactVersion: string;
    latestReleaseDate: string;
};

type Project = {
    software: Software[];
    projectName: string;
};

type EolAnalytics = {
    now: string[];
    "<6months": string[];
    "<12months": string[];
    ">12months": string[];
};

type ProjectData = {
    projects: Project[];
    eolAnalytics: EolAnalytics;
};

type PlotTypeData = {
    name : string;
    value: any
}[];

const topCardStyle: CSSProperties = {
    margin: 24,
    background: "#fafafa",
    boxShadow: "5px 10px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    height: 300,
};

const eolCardStyle: CSSProperties = {
    margin: 24,
    background: "#fafafa",
    boxShadow: "5px 10px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
};

const CardHeadStyle = {
    // background: "linear-gradient(45deg, #081524 50%, #2f54eb)",
    background: "#fafafa",
    color: "#000000",
    fontSize: "18px",
    border: 0,
};

const HomePage: NextPage = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { getAccessTokenSilently } = useAuth0();
    const [packageTypeData, setPackageTypeData] = useState<PlotTypeData>([]);
    const [upcomingEolData, setUpcomingEolData] = useState<PlotTypeData>([]);
    const [projectsList, setProjectsList] = useState<Project[]>([]);

    const calculatePackageTypeData = (projects: Project[]) => {
        const eolTypeCounts: { [key: string]: number } = {};

        if (!projects) {
            return [];
        }

        projects.forEach((project: Project) => {
            project.software.forEach((software: Software) => {
                if (eolTypeCounts[software.artifactType]) {
                    eolTypeCounts[software.artifactType] += 1;
                } else {
                    eolTypeCounts[software.artifactType] = 1;
                }
            });
        });

        return Object.entries(eolTypeCounts).map(([name, value]) => ({
            name,
            value,
        }));
    };

    const calculateUpcomingEolData = (eolAnalytics: EolAnalytics) => {
      return [
          { name: "EOL", value: eolAnalytics.now.length },
          { name: "EOL <6mo", value: eolAnalytics["<6months"].length },
          { name: "EOL <12mo", value: eolAnalytics["<12months"].length },
          { name: "EOL >12mo", value: eolAnalytics[">12months"].length },
      ];
    };

    useEffect(() => {
      const fetchProjectData = async () => {
          try {
              const token = await getAccessTokenSilently();
              OpenAPI.BASE = API_URL!;
              OpenAPI.HEADERS = {
                Authorization: `Bearer ${token}`,
              };
              await DefaultService.getProjectDataProjectDataGet().then((response) => {
                setProjectsList(response.project_data.projects)
                setPackageTypeData(calculatePackageTypeData(response.project_data.projects));
                setUpcomingEolData(calculateUpcomingEolData(response.project_data.eolAnalytics));
              })
          } catch (error) {
              console.error(error);
          }
      };
      fetchProjectData();
  }, [getAccessTokenSilently, API_URL]);

    const fetchSoftwareIcon = (softwareName: string) => {
        const name = softwareName.toLowerCase();

        if (name.includes("ubuntu")) {
        return "https://upload.wikimedia.org/wikipedia/commons/a/ab/Logo-ubuntu_cof-orange-hex.svg";
        } else if (name.includes("mongo")) {
        return "https://upload.wikimedia.org/wikipedia/commons/0/00/Mongodb-svgrepo-com.svg";
        } else if (name.includes("alpine")) {
        return "https://upload.wikimedia.org/wikipedia/commons/2/2c/Alpine_Linux_logo.png";
        } else if (name.includes("fedora")) {
        return "https://upload.wikimedia.org/wikipedia/commons/3/3f/Fedora_logo.svg";
        } else if (name.includes("debian")) {
        return "https://upload.wikimedia.org/wikipedia/commons/8/86/Debian-logo-notext_heyiya-if.svg";
        } else if (name.includes("arch")) {
        return "https://upload.wikimedia.org/wikipedia/commons/7/7d/Arcolinux.svg";
        } else if (name.includes("postgres")) {
        return "https://upload.wikimedia.org/wikipedia/commons/2/2e/Pg_logo.png";
        } else if (name.includes("windows")) {
        return "https://upload.wikimedia.org/wikipedia/commons/5/5f/Windows_logo_-_2012.svg";
        } else if (name.includes("sqlite")) {
        return "https://upload.wikimedia.org/wikipedia/commons/9/97/Sqlite-square-icon.svg";
        } else if (name.includes("kubernetes")) {
        return "https://upload.wikimedia.org/wikipedia/commons/3/39/Kubernetes_logo_without_workmark.svg";
        } else if (name.includes("centos")) {
        return "https://upload.wikimedia.org/wikipedia/commons/9/9e/CentOS_Graphical_Symbol.svg";
        } else if (name.includes(".net")) {
        return "https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg";
        } else if (name.includes("python")) {
        return "https://upload.wikimedia.org/wikipedia/commons/1/1f/Python_logo_01.svg";
        } else if (name.includes("vue")) {
        return "https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg";
        } else if (name.includes("react")) {
        return "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg";
        } else if (name.includes("golang")) {
        return "https://upload.wikimedia.org/wikipedia/commons/2/2d/Go_gopher_favicon.svg";
        } else if (name.includes("node")) {
        return "https://upload.wikimedia.org/wikipedia/commons/7/70/Font_Awesome_5_brands_node-js.svg";
        } else {
        return "";
        }
    };

    const toHumanReadableDate = (dateString: string) => {
            const [year, month, day] = dateString.split("-");
            const date = new Date(Number(year), Number(month) - 1, Number(day));
            const options: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            return date.toLocaleDateString(undefined, options);
    };

    const renderEolSoftwareListItemTitle = (
        software: string,
        version: string,
        eolDate: string,
        eolState: string
    ) => {
        const eolBadge = (
            <Badge
                count={"eol"}
                style={{ background: "linear-gradient(135deg, #ff4d4f, #cf1322)" }}
                size="small"
            />
        );
        const soonBadge = (
            <Badge
                count={"soon"}
                style={{ background: "linear-gradient(135deg, #ffc53d, #d48806)" }}
                size="small"
            />
        );

        let badge = null;

        if (eolState === "eol") {
            badge = eolBadge;
        } else if (eolState === "soon") {
            badge = soonBadge;
        }

        return (
            <Row>
                <Col span={8}>
                    <Title level={5}>{software}</Title>
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={24}>{version}</Col>
                        <Col span={0}>{badge}</Col>
                    </Row>
                </Col>
                <Col span={8}>
                    {toHumanReadableDate(eolDate)}
                </Col>
            </Row>
        );
    };

    const renderEolSoftwareListItemDesc = (
        imageLocation: string,
        imageSource: string
    ) => {
        return imageLocation + " · " + imageSource;
    };

    const renderList = (eolSoftware: []) => {
        return (
        <List
            itemLayout="horizontal"
            dataSource={eolSoftware}
            renderItem={(item: any, _) => (
            <List.Item style={{ padding: "12px" }}>
                <List.Item.Meta
                    avatar={
                        <Avatar src={fetchSoftwareIcon(item.productName)}/>
                    }
                    title={renderEolSoftwareListItemTitle(
                        item.productName,
                        item.artifactVersion,
                        item.eolDate,
                        item.eol
                    )}
                    description={renderEolSoftwareListItemDesc(
                        item.imageLocation,
                        item.imageName
                    )}
                />
            </List.Item>
            )}
        />
        );
    };

    const renderCollapseHeader = (projectName: string, softwareList: Software[]) => {
        const eolCounts = {
            critical: 0,
            med: 0,
            low: 0,
        };

        softwareList?.forEach((software) => {
            if (software.eol === "now") {
                eolCounts.critical++;
            }
            if (software.eol === "<6months" || software.eol === "<12months") {
                eolCounts.med++;
            }
            if (software.eol === ">12months") {
                eolCounts.low++;
            }
        });

        return (
        <Row>
            <Col span={16}>
                <Text strong>{projectName}</Text>
            </Col>
            <Col span={6}>
                <Badge
                    count={eolCounts["critical"]}
                    style={{
                        background: "#f5222d",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                    showZero={true}
                />
                <Badge
                    count={eolCounts["med"]}
                    style={{
                        background: "#faad14",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                    showZero={true}
                />
                <Badge
                    count={eolCounts["low"]}
                    style={{
                        background: "#52c41a",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }}
                    showZero={true}
                />
            </Col>
            <Col span={2}>
                <PDFGenerator
                    softwareList={softwareList}
                    projectName={projectName}
                ></PDFGenerator>
            </Col>
        </Row>
        );
    };

    const renderCollapsePanel = (projects: any) => {
        return (
            <Collapse ghost>
                {projects.map((project: any) => (
                    <Panel
                        header={renderCollapseHeader(project.projectName, project.software)}
                        key={project.projectName}
                    >
                        {renderList(project.software)}
                    </Panel>
                ))}
            </Collapse>
        );
    };



    return (
        <Content>
            <Row>
              {projectsList && projectsList.length >= 1 ? (
                <>
                <Col span={12}>
                    <Card
                        style={topCardStyle}
                        title="Package Types"
                        size="small"
                        headStyle={CardHeadStyle}
                        bordered={false}
                    >
                        <PieChart width={300} height={240} data={packageTypeData}></PieChart>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        style={topCardStyle}
                        title="Upcoming EOL"
                        size="small"
                        headStyle={CardHeadStyle}
                        bordered={false}
                    >
                        <BarChart width={300} height={240} data={upcomingEolData}></BarChart>
                    </Card>
                </Col>
                </>
                ) : null}
            </Row>
            <Row>
                <Col span={24}>
                    <Card
                        style={eolCardStyle}
                        title="Projects"
                        size="small"
                        headStyle={CardHeadStyle}
                        bordered={false}
                    >
                    {projectsList && projectsList.length >= 1 ? (
                      <Row style={{ marginTop: "24px" }}>
                        <Col span={24}>{renderCollapsePanel(projectsList)}</Col>
                      </Row>
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No projects found"
                      />
                    )}
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};

export default HomePage;
