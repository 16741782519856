import { CloudDownloadOutlined } from "@ant-design/icons";
import {
    Document,
    Page,
    pdf,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import { Button } from "antd";
import { saveAs } from "file-saver";
import React from "react";

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    header: {
        fontSize: 24,
        marginBottom: 10,
    },
    date: {
        fontSize: 12,
        marginBottom: 30,
    },
    projectName: {
        fontSize: 16,
        marginBottom: 10,
    },
    table: {
        display: "flex",
        width: "auto",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableColHeader: {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#bfbfbf",
    },
    tableCol: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5,
    },
    tableCell: {
        fontType: "bold",
        margin: "auto",
        marginTop: 5,
        fontSize: 10,
    },
});

interface TableRowProps {
    name: string;
    version: string;
    eolDate: string;
    imageLocation: string;
    imageName: string;
}

const TableRow = ({
    name,
    version,
    eolDate,
    imageLocation,
    imageName,
}: TableRowProps) => (
    <View style={styles.tableRow}>
        <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{name}</Text>
        </View>
        <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{version}</Text>
        </View>
        <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{eolDate}</Text>
        </View>
        <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{imageLocation}</Text>
        </View>
        <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{imageName}</Text>
        </View>
    </View>
);

function MyDocument({ softwareList, projectName }: DataProp) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <Text style={styles.header}>Xeol</Text>
                    <Text style={styles.date}>date: {new Date().toLocaleDateString()}</Text>
                    <Text style={styles.projectName}>project: {projectName}</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Name</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Version</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Eol Date</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Image Location</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Image Name</Text>
                        </View>
                    </View>
                    {softwareList.map((item, index) => (
                        <TableRow
                            key={index}
                            {...{
                                name: item.productName,
                                version: item.artifactVersion,
                                eolDate: item.eolDate,
                                imageLocation: item.imageLocation,
                                imageName: item.imageName,
                            }}
                        />
                    ))}
                </View>
            </Page>
        </Document>
    );
}

interface Software {
    id: string;
    eol: string;
    purl: string;
    scanId: string;
    eolDate: string;
    imageName: string;
    productName: string;
    releaseDate: string;
    artifactName: string;
    artifactType: string;
    releaseCycle: string;
    imageLocation: string;
    artifactVersion: string;
    latestReleaseDate: string;
}

interface DataProp {
    softwareList: Software[];
    projectName: string;
}

function PDFGenerator({ softwareList, projectName }: DataProp) {
    const [loading, setLoading] = React.useState(false);

    const downloadPDF = async () => {
        setLoading(true);
        const blob = await pdf(
            <MyDocument softwareList={softwareList} projectName={projectName} />
        ).toBlob();
        const date = new Date().toLocaleDateString();
        saveAs(blob, `${projectName}-${date}.pdf`);
        setLoading(false);
    };

    return (
        <Button
        type="link"
        icon={<CloudDownloadOutlined style={{ fontSize: "20px" }} />}
        block
        onClick={downloadPDF}
        disabled={loading}
        >
        {loading ? "Loading document..." : ""}
        </Button>
    );
}

export { PDFGenerator };