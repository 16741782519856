import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
    ApiOutlined,
    AreaChartOutlined,
    BranchesOutlined,
    ExperimentOutlined,
    LogoutOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
  } from '@ant-design/icons';
import { Avatar, Badge, Image, Layout, Menu, Typography } from 'antd';

const { Sider } = Layout;
const { Text } = Typography;

const LeftMenu: React.FC = () => {
    const { logout, user } = useAuth0();
    const location = useLocation();

    const menuItems = [
        {
            key: '/',
            icon: <AreaChartOutlined />,
            label: 'Home',
            path: '/',
            disabled: false,
        },
        {
            key: '/policies',
            icon: <BranchesOutlined />,
            label: 'Policies',
            path: '/policies',
            disabled: false,
        },
        {
            key: '/graph',
            icon: <ExperimentOutlined />,
            label: "Graph",
            path: '/graph',
            disabled: true,
        },
        {
            key: '/integrations',
            icon: <ApiOutlined />,
            label: 'Integrations',
            path: '/integrations',
            disabled: false,
        },
        {
            key: '/team',
            icon: <TeamOutlined />,
            label: 'Team',
            path: '/team',
            disabled: false,
        },
        {
            key: '/settings',
            icon: <SettingOutlined />,
            label: "Settings",
            path: '/settings',
            disabled: false,
        },
    ];

    const menuStyle = {
        height: '100vh',
    };

    const profileStyle: React.CSSProperties = {
        position: 'absolute',
        width: "100%",
        bottom: 0,
        zIndex: 1,
    };

    const imageStyle = {
        padding: 24,
    };

    const renderMenuItem = (item: any) => {
        if (item.disabled) {
            return (
                <Badge.Ribbon text="Coming Soon">
                    <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                        {item.label}
                    </Menu.Item>
                </Badge.Ribbon>
            )
        } else {
            return (
                <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                    <Link to={item.path}>
                        {item.label}
                    </Link>
                </Menu.Item>
            )
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem("organizationId");
        logout({ logoutParams: { returnTo: window.location.origin } })
    };

    return (
        <Sider trigger={null} collapsible>
            <Menu
                theme="dark"
                mode="vertical"
                defaultSelectedKeys={[location.pathname]}
                style={menuStyle}
            >
                <div style={imageStyle}>
                    <Image
                        width={144}
                        preview={false}
                        src="https://s3.amazonaws.com/static.xeol.io/xeol-logo/full-white-logo.png"
                    />
                </div>

                {menuItems.map((item) => (renderMenuItem(item)))}

                <Menu.SubMenu
                    key="profile-section"
                    style={profileStyle}
                    title={user?.name}
                    // icon={<Avatar src={user?.picture}/>}
                    icon={<UserOutlined />}
                >
                    <Menu.Item key="user-email" disabled>{user?.email}</Menu.Item>
                    <Menu.Item key="logout-button" onClick={handleLogout}>
                        <LogoutOutlined /> Logout
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        </Sider>
    )
};

export default LeftMenu;
