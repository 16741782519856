import React from 'react';
import { Layout, Row, Col } from 'antd';
import IntegrationCard from '../components/integrationCard';

const { Content } = Layout;

const WizIntegration = {
    avatar: "",
    title: "Wiz",
    description: "Push your data into Wiz"
};

const ErmeticIntegration = {
    avatar: "",
    title: "Ermetic",
    description: "Push your data into Ermertic"
};

const LaceworkIntegration = {
    avatar: "",
    title: "Lacework",
    description: "Push your data into Lacework"
};

const OpsLevelIntegration = {
    avatar: "",
    title: "OpsLevel",
    description: "Push your data into OpsLevel"
};

const BackstageIntegration = {
    avatar: "",
    title: "Backstage",
    description: "Push your data into Backstage"
};

const CortexIntegration = {
    avatar: "",
    title: "Cortex",
    description: "Push your data into Cortex"
};

const integrations = [
    [WizIntegration, ErmeticIntegration, LaceworkIntegration],
    [OpsLevelIntegration, BackstageIntegration, CortexIntegration],
];

const IntegrationsPage: React.FC = () => {
    return (
        <Content>
            {integrations.map((row, rowIndex) => (
                <Row key={rowIndex} style={{ marginBottom: "24px" }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {row.map((item, index) => (
                        <Col key={index} className="gutter-row" span={8}>
                            <IntegrationCard integration={item} />
                        </Col>
                    ))}
                </Row>
            ))}
        </Content>
    )
};

export default IntegrationsPage;