import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, Avatar } from 'antd';

const LoginPage: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    const loginPageStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    const avatarStyle = {
        marginBottom: 24, 
    };

    const cardStyle = {
        width: 300,
    };

    const cardBodyStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <div style={loginPageStyle}>
            <Card
                style={cardStyle}
                bodyStyle={cardBodyStyle}
            >
                <Avatar style={avatarStyle} size={64} src="https://s3.amazonaws.com/static.xeol.io/xeol-logo/full-white-logo.png" />
                <Button type="primary" onClick={() => loginWithRedirect()}>Login</Button>
            </Card>
        </div>
    );
};

export default LoginPage;