import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Avatar, Card, Switch } from 'antd';

const { Meta } = Card;

interface integration {
    avatar: string,
    title: string,
    description: string,
}

const IntegrationCardStyle = {
    minWidth: 300,
};

const CardMetaStyle = {
    height: 100,
};

const IntegrationCard: React.FC<{integration: integration}> = ({integration}) => {
    return (
        <Card
            // style={IntegrationCardStyle}
            actions={[
                <SettingOutlined key="setting" />,
                <Switch checkedChildren="On" unCheckedChildren="Off" key="switch"/>
            ]}
        >
            <Meta
                // style={CardMetaStyle}
                avatar={<Avatar src={integration.avatar} size="large"/>}
                title={integration.title}
                description={integration.description}
            />
        </Card>
    )
};

export default IntegrationCard;