import { Content } from "antd/es/layout/layout";
import React from "react";


const PageStyle = {
  margin: '24px 16px',
  padding: 24,
  minHeight: 280,
};


export const NotFoundPage = () => {
  return (
    <Content
    style={PageStyle}
  >
  <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Not Found
        </h1>
      </div>
</Content>
  );
};
