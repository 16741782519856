import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { 
    Button,
    DatePicker,
    Form,
    Layout,
    Modal,
    Select,
    Space,
    Typography,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import Card from '../components/card';

const { Text } = Typography;


type Policy = {
    id: string;
    policy_type: string;
    warn_date: string;
    deny_date: string;
    product_name: string;
    cycle: string;
    cycle_operator: string;
    organization: string;
};

type EolSoftware = {
    id: string;
    organization_id: string;
    scan_id: string;
    product_name: string;
    release_cycle: string;
    eol_date: string;
    latest_release_date: string;
    release_date: string;
    artifact_name: string;
    artifact_version: string;
    artifact_type: string;
    purl: string;
}

type OptionType = {
    value: string;
    label: string;
};

type SoftwareAndVersionListType = {
    [key: string]: { value: string, label: string }[];
};

const PoliciesPage: React.FC = () => {
    const [policies, setPolicies] = useState<Policy[]>([]);
    const [currentPolicy, setCurrentPolicy] = useState<Policy | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPolicyEditModalOpen, setIsPolicyEditModalOpen] = useState(false);
    const [isPolicyAddModalOpen, setIsPolicyAddModalOpen] = useState(false);
    const [softwareAndVersionList, setSoftwareAndVersionList] = useState<SoftwareAndVersionListType>({});
    const [versionList, setVersionList] = useState<OptionType[]>([]);
    const [softwareList, setSoftwareList] = useState<OptionType[]>([]);

    const { getAccessTokenSilently } = useAuth0();
    const API_URL = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();

    const showDeleteModal = (policy: Policy) => {
        setCurrentPolicy(policy);
        setIsDeleteModalOpen(true);
    };
  
    const handleDeleteOk = async () => {
        try {
            const token = await getAccessTokenSilently();
            await axios.delete(
                `${API_URL}/v1/policy/${currentPolicy?.id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setPolicies(policies.filter(policy => policy.id !== currentPolicy?.id));
            setCurrentPolicy(null);
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error(error);
        }
    };
  
    const handleDeleteCancel = () => {
        setCurrentPolicy(null);
        setIsDeleteModalOpen(false);
    };

    const showPolicyEditModal = (policy: Policy) => {
        form.setFieldsValue({
            policy_type: policy.policy_type || "",
            warn_date: dayjs(policy.warn_date) || null,
            deny_date: dayjs(policy.deny_date) || null,
            product_name: policy.product_name || "",
            cycle: policy.cycle || "",
            cycle_operator: policy.cycle_operator || "",
        });

        setCurrentPolicy(policy);
        setIsPolicyEditModalOpen(true);
    };

    const handlePolicyEditSave = () => {
        setCurrentPolicy(null);
        setIsPolicyEditModalOpen(false);
    };

    const handlePolicyEditCancel = () => {
        form.resetFields();
        setCurrentPolicy(null);
        setIsPolicyEditModalOpen(false);
    };

    const showPolicyAddModal = () => {
        const fetchEolSoftware = async () => {
            const token = await getAccessTokenSilently();
            const res = await axios.get(
                `${API_URL}/v1/scanner/eol_software`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setSoftwareList(getProductNames(res.data));
            setVersionList(getReleaseCycles(res.data));
            setSoftwareAndVersionList(getSoftwareAndReleaseCycles(res.data));
        };

        fetchEolSoftware()
        setCurrentPolicy(null);
        setIsPolicyAddModalOpen(true);
    };

    const handlePolicyAddSave = () => {
        setCurrentPolicy(null);
        setIsPolicyAddModalOpen(false);
    };

    const handlePolicyAddCancel = () => {
        form.resetFields();
        setCurrentPolicy(null);
        setIsPolicyAddModalOpen(false);
    };

    const getProductNames = (eolSoftwareList: EolSoftware[]) => {
        return eolSoftwareList.reduce((acc: { value: string; label: string }[], software: EolSoftware) => {
            if (!acc.find(item => item.value === software.product_name)) {
                acc.push({
                    value: software.product_name,
                    label: software.product_name,
                });
            }
            return acc;
        }, []);  
    };

    const getReleaseCycles = (eolSoftwareList: EolSoftware[]) => {
        return eolSoftwareList.reduce((acc: { value: string; label: string }[], software: EolSoftware) => {
            if (!acc.find(item => item.value === software.release_cycle)) {
                acc.push({
                    value: software.release_cycle,
                    label: software.release_cycle,
                });
            }
            return acc;
        }, []);  
    };

    const getSoftwareAndReleaseCycles = (eolSoftwareList: EolSoftware[]): SoftwareAndVersionListType => {
        const result: SoftwareAndVersionListType = {};
    
        eolSoftwareList.forEach((software: EolSoftware) => {
            if (!result[software.product_name]) {
                result[software.product_name] = [];
            }
    
            if (!result[software.product_name].some(item => item.value === software.release_cycle)) {
                result[software.product_name].push({
                    value: software.release_cycle,
                    label: software.release_cycle
                });
            }
        });
    
        return result;
    };
    
    const handleSelectChange = (value: string, option: any) => {
        setVersionList(softwareAndVersionList[value])
    };

    const handleCreatePolicy = () => {
        showPolicyAddModal();
    };

    const renderPolicies = () => {
        const renderCardText = (policy: Policy): string => {
            return `${policy.policy_type} policy for ${policy.product_name} ${policy.cycle}`;
        };

        const cycleOperatorToHumanReadable = (policyOperator: string): string => {
            switch (policyOperator) {
                case "LTE":
                    return "≤";
                case "LT":
                    return "<";
                case "EQ":
                    return "";
                default:
                    return "";
            }
        };

        const renderCardContent = (policy: Policy) => {
            return (
                <Space align="start" direction="vertical">
                    <Text>All buils with {policy.product_name} version {cycleOperatorToHumanReadable(policy.cycle_operator)}{policy.cycle} will:</Text>
                    <Text>1. WARN until {policy.warn_date}</Text>
                    <Text>2. FAIL starting {policy.deny_date}</Text>
                </Space>
            )
        };

        return policies.map((policy: Policy) => (
            <Card 
                key={policy.id}
                title={renderCardText(policy)}
                width={500}
                actions={[
                    <Button 
                        key="edit"
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => showPolicyEditModal(policy)}
                    >
                        Edit
                    </Button>,
                    <Button 
                        key="delete"
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{ color: "red" }}
                        onClick={() => showDeleteModal(policy)}
                    >
                        Delete
                    </Button>]
                }
            >
                {renderCardContent(policy)}
            </Card>
        ))
    };

    const inPlacePolicyListReplace = (policies: Policy[], newPolicy: Policy) => {
        const newPolicies = [];

        for (const policy of policies) {
            if (policy.id == newPolicy.id) {
                newPolicies.push(newPolicy);
            } else {
                newPolicies.push(policy);
            }
        }

        return newPolicies;
    };

    const onFormSubmit = async (values: any) => {
        values.warn_date = values.warn_date.format("YYYY-MM-DDT00:00:00");
        values.deny_date = values.deny_date.format("YYYY-MM-DDT00:00:00");

        try {
            const token = await getAccessTokenSilently();

            if (currentPolicy) {
                const res = await axios.patch(
                    `${API_URL}/v1/policy/${currentPolicy.id}`,
                    values,
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                setPolicies(inPlacePolicyListReplace(policies, res.data));
                handlePolicyEditCancel();
            } else {
                const res = await axios.post(
                    `${API_URL}/v1/policy`,
                    values,
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                policies.push(res.data);
                handlePolicyAddCancel();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const deleteModalFooterButtons = [
        <Button key="cancel-delete" onClick={handleDeleteCancel}>
            Cancel
        </Button>,
        <Button key="ok-delete" danger type="primary" onClick={handleDeleteOk}>
            DELETE
        </Button>,
    ];

    useEffect(() => {
        const fetchPolicies = async () => {
            try {
                const token = await getAccessTokenSilently();
                const res = await axios.get(
                    `${API_URL}/v1/policy`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                
                setPolicies(res.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchPolicies();
    }, [getAccessTokenSilently, API_URL]);

    return (
        <Space align="start" direction="vertical">
            <Button type="primary" onClick={handleCreatePolicy}>
                New Policy
            </Button>
            {renderPolicies()}
            <Modal 
                title="Remove Policy"
                open={isDeleteModalOpen}
                onCancel={handleDeleteCancel}
                footer={deleteModalFooterButtons}
            >
                Are you sure you want to delete this policy?
            </Modal>
            <Modal
                title={currentPolicy ? "Edit Policy" : "Add Policy"}
                open={isPolicyEditModalOpen || isPolicyAddModalOpen}
                onCancel={currentPolicy ? handlePolicyEditCancel : handlePolicyAddCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    name="policy-form"
                    onFinish={onFormSubmit}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item label="Policy Type">
                        <Space>
                            <Form.Item
                                name="policy_type"
                                noStyle
                                rules={[{ required: true, message: 'Policy type is required' }]}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={[{ value: "EOL", label: "EOL" }]}
                                    disabled={currentPolicy ? true : false}
                                />            
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Warn Until">
                        <Space>
                            <Form.Item
                                name="warn_date"
                                noStyle
                                rules={[{ required: true, message: 'Warn date is required' }]}
                            >
                                <DatePicker 
                                    format="YYYY-MM-DD"
                                />        
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Block On">
                        <Space>
                            <Form.Item
                                name="deny_date"
                                noStyle
                                rules={[{ required: true, message: 'Block date type is required' }]}
                            >
                                <DatePicker 
                                    format="YYYY-MM-DD"
                                />             
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Software Name">
                        <Space>
                            <Form.Item
                                name="product_name"
                                noStyle
                                rules={[{ required: true, message: 'Software name is required' }]}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={softwareList}
                                    onChange={handleSelectChange}
                                    disabled={currentPolicy ? true : false}
                                />            
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Software Version">
                        <Space>
                            <Form.Item
                                name="cycle"
                                noStyle
                                rules={[{ required: true, message: 'Software version is required' }]}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={versionList}
                                    disabled={currentPolicy ? true : false}
                                />            
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="Version Operator">
                        <Space>
                            <Form.Item
                                name="cycle_operator"
                                noStyle
                                rules={[{ required: true, message: 'Version Operator is required' }]}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={[{ value: "LTE", label: "≤" }, { value: "LT", label: "<" }, { value: "EQ", label: "=" }]}
                                />            
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    <Form.Item label="buttons" noStyle>
                        <Space align="end" direction="horizontal" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button key="cancel-add-edit" onClick={currentPolicy ? handlePolicyEditCancel : handlePolicyAddCancel}>
                                Cancel
                            </Button>
                            <Button key="ok-add-edit" type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    )
};

export default PoliciesPage;
