import React from "react";
import { Column, ColumnConfig } from "@ant-design/plots";

interface BarChartProps {
    width: number;
    height: number;
    data: any;
}

const BarChart: React.FC<BarChartProps> = ({ width, height, data }) => {
    const columnConfig: ColumnConfig = {
        data: data,
        xField: "name",
        yField: "value",
        width: width,
        height: height,
        padding: [20, 30, 20, 30],
        label: {
            position: "middle",
        },
        xAxis: {
            label: {},
        },
        yAxis: {
            label: {},
        },
    };

    return <Column {...columnConfig} />;
};

export default BarChart;