import React, { useState, useEffect, ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Card, Input, Layout, List, Skeleton } from 'antd';
import axios from 'axios';

const { Content } = Layout;

const CompanyCardStyle = {
    margin: 24,
};

const InputStyle = {
    maxWidth: 300,
    margin: 6,
};

const ButtonStyle = {
    margin: 6,
};

const ListStyle = {
    margin: 6,
};

const TeamPage: React.FC = () => {
    const [usersList, setUsersList] = useState([]);
    const [invitee, setInvitee] = useState("");
    const { user, getAccessTokenSilently } = useAuth0();
    const API_URL = process.env.REACT_APP_API_URL;

    interface User {
        email: string;
        picture: string;
        name: string;
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInvitee(e.target.value);
    };

    const inviteUser = async () => {
        console.log("invite");
    };

    return (
        <Content>
            <Card bordered={false} style={CompanyCardStyle}>
                <Input style={InputStyle} placeholder="email" onChange={handleInputChange}/>
                <Button style={ButtonStyle} type="primary" onClick={() => inviteUser()}>Invite</Button>
                <List
                    style={ListStyle}
                    itemLayout="horizontal"
                    dataSource={usersList}
                    renderItem={(user: User) => (
                        <List.Item
                            actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">remove</a>]}
                        >
                            <List.Item.Meta
                                // avatar={<Avatar src={user.picture} />}
                                title={user.name}
                                description={user.email}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </Content>
    )
};

export default TeamPage;
