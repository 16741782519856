/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationsModel } from '../models/OrganizationsModel';
import type { OrganizationsPatchModel } from '../models/OrganizationsPatchModel';
import type { PoliciesModel } from '../models/PoliciesModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Create Organization
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createOrganizationOrganizationsPost(
        requestBody: OrganizationsModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Organization
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readOrganizationOrganizationsIdGet(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Organization
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateOrganizationOrganizationsIdPatch(
        id: string,
        requestBody: OrganizationsPatchModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Organization
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteOrganizationOrganizationsIdDelete(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Organization By Auth0 Id
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readOrganizationByAuth0IdOrganizationsAuth0IdGet(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/auth0/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Api Key
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createApiKeyOrganizationsIdApiKeyPost(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/{id}/api_key',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Users
     * @param auth0OrgId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readUsersUsersAuth0OrgIdGet(
        auth0OrgId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{auth0_org_id}',
            path: {
                'auth0_org_id': auth0OrgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Policies
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listPoliciesV1PolicyGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/policy',
        });
    }

    /**
     * Create Policy
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createPolicyV1PolicyPost(
        requestBody: PoliciesModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/policy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Policy
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deletePolicyV1PolicyIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/policy/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Policy
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getPolicyV1PolicyIdGet(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/policy/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Data
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getProjectDataProjectDataGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project_data',
        });
    }

    /**
     * Get Health
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getHealthGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

}
