import React, { CSSProperties } from "react";
import { Card as AntdCard } from "antd";

interface CardProps {
    width?: number;
    height?: number;
    title: string;
    children?: React.ReactNode;
    extra?: any;
    actions?: any;
}

const Card: React.FC<CardProps> = ({ width, height, title, children, extra, actions }) => {    
    const CardBodyStyle: CSSProperties = {
        margin: 24,
        // background: "#ffffff",
        // boxShadow: "5px 10px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: 10,
        width: width,
        height: height,
    };
    
    const CardHeadStyle: CSSProperties = {
        background: "#ffffff",
        color: "#000000",
        fontSize: "18px",
    };

    return (
        <AntdCard
            style={CardBodyStyle}
            title={title}
            size="small"
            // headStyle={CardHeadStyle} 
            // bordered={false}
            extra={extra}
            actions={actions}
        >
            {children}
        </AntdCard>
    )
};

export default Card;