import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { App, ConfigProvider, Layout } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import Menu from './components/menu';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import PoliciesPage from './pages/policies';
import IntegrationsPage from './pages/integrations';
import TeamPage from './pages/team';
import SettingsPage from './pages/settings';
import { NotFoundPage } from './pages/not-found-page';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Content } from 'antd/es/layout/layout';


const queryClient = new QueryClient();
const { Sider } = Layout;

const XeolApp: React.FC = () => {
    const { isLoading, isAuthenticated } = useAuth0();

    if (isLoading) {
      return <span>loading...</span>;
    }

    const LeftMenuSiderStyle = {
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
    } as React.CSSProperties;

    const RightContentStyle = {
        marginLeft: 200,
        height: "100vh",
        backgroundColor: "#ffffff",
    } as React.CSSProperties;

    const ContentStyle = {
        padding: 24,
    };

    const renderApp = () => {
        if (isAuthenticated) {
            return (
                <QueryClientProvider client={queryClient}>
                    <Layout hasSider>
                    <Sider style={LeftMenuSiderStyle}>
                        <Menu/>
                    </Sider>
                    <Layout style={RightContentStyle}>
                        <Content style={ContentStyle}>
                            <Routes>
                                <Route path='/' element={<HomePage/>}/>
                                {/* <Route path='/graph' element={<GraphPage/>}/> */}
                                <Route path='/policies' element={<PoliciesPage/>}/>
                                <Route path='/integrations' element={<IntegrationsPage/>}/>
                                <Route path='/team' element={<TeamPage/>}/>
                                <Route path='/settings' element={<SettingsPage/>}/>
                                <Route path="*" element={<NotFoundPage/>}/>
                            </Routes>
                        </Content>
                    </Layout>
                    </Layout>
                </QueryClientProvider>
            );
        } else {
            return <LoginPage/>;
        }
    };

    return (
        <ConfigProvider>
            <App>{renderApp()}</App>
        </ConfigProvider>
    );
};

export default XeolApp;
