import React from "react";
import { Pie, PieConfig } from "@ant-design/plots";

interface PieChartProps {
    width: number;
    height: number;
    data: any;
}

const PieChart: React.FC<PieChartProps> = ({ width, height, data }) => {
    const pieConfig: PieConfig = {
        appendPadding: 10,
        data: data,
        angleField: "value",
        colorField: "name",
        radius: 1,
        innerRadius: 0.6,
        width: width,
        height: height,
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}",
            style: {
                textAlign: "center",
                fontSize: 14,
            },
        },
        interactions: [
            { type: "element-selected" },
            { type: "element-active" },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                content: "",
            },
        },
    };

    return <Pie {...pieConfig} />;
};

export default PieChart;