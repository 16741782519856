import React, { useState, useEffect, ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, Input, Layout, Row, Typography } from 'antd';
import axios from 'axios';

const { Content } = Layout;
const { Text } = Typography;

const CompanyCardStyle = {
    margin: 24,
};

const InputStyle = {
    maxWidth: 350,
    margin: 6,
};

const ButtonStyle = {
    width: 100,
    margin: 6,
};

const TextStyle = {
    margin: 6,
};

const SettingsPage: React.FC = () => {
    const [orgName, setOrgName] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [orgId, setOrgId] = useState("");
    const { user, getAccessTokenSilently } = useAuth0();
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchOrg = async () => {
            try {
                const token = await getAccessTokenSilently();
                const res = await axios.get(
                    `${API_URL}/organizations/auth0/${user?.org_id}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                if (res.data.api_key !== null) {
                    setApiKey(res.data.api_key)
                }
                setOrgName(res.data.name);
                setOrgId(res.data.id);
            } catch (error) {
                console.error(error);
            }
        };
        fetchOrg();
    }, []);

    const handleOrgNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOrgName(e.target.value);
    };

    const handleUpdateClick = async () => {
        try {
            const token = await getAccessTokenSilently();
            const res = await axios.patch(
                `${API_URL}/organizations/${orgId}`,
                { name: orgName },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setOrgName(res.data.name);
        } catch (error) {
            console.error(error);
        }
    };

    const handleGenerateApiKeyClick = async () => {
        try {
            const token = await getAccessTokenSilently();
            const res = await axios.post(
                `${API_URL}/organizations/${orgId}/api_key`,
                {},
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setApiKey(res.data.api_key);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Content>
            <Card title="Company Profile" bordered={false} style={CompanyCardStyle}>
            <Row align="middle">
                <Input style={InputStyle} value={orgName} onChange={handleOrgNameInputChange}/>
                <Button style={ButtonStyle} type="primary" onClick={() => handleUpdateClick()}>Update</Button>
            </Row>
            <Row align="middle">
                <Input style={InputStyle} placeholder="API Key" disabled={true} value={apiKey}/>
                <Button style={ButtonStyle} type="primary" onClick={() => handleGenerateApiKeyClick()}>Generate</Button>
            </Row>
            </Card>
        </Content>
    )
};

export default SettingsPage;
